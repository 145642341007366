<template>
  <Loader :loading="isLoading">
    <form @submit.prevent="submit" class="kt-form">
      <div
          v-if="error"
          class="col"
      >
        <div
            class="alert alert-danger"
            role="alert"
        >
          {{ error }}
        </div>
      </div>

      <template
        v-if="sent"
      >
        <div
          class="alert alert-danger"
          role="alert"
        >
          {{ $t('login.reset_password_instruction2') }}
        </div>
      </template>
      <template v-else>
        <LandingInput
          v-model="values.email"
          type="email"
          name="email"
          :placeholder="$t('login.email_ph')"
          :errors="errors"
        />

        <div class="kt-login__actions">
          <button
                  type="submit"
                  :disabled="isLoading"
                  id="kt_login_forgot_submit"
                  class="btn btn-brand btn-elevate kt-login__btn-primary"
          >
            {{ $t('login.reset_password_action') }}
          </button>
          &nbsp;&nbsp;
          <button
                  id="kt_login_forgot_cancel"
                  class="btn btn-light btn-elevate kt-login__btn-secondary"
                  @click.prevent="$router.push({name: 'login'})"
          >
            {{ $t('general.cancel') }}
          </button>
        </div>
      </template>
    </form>
  </Loader>
</template>

<script>
import Loader from '../global/Loader'
import LandingInput from './LandingInput'
import formHasErrors from '../../mixins/formHasErrors'
import isEmail from 'validator/lib/isEmail'

export default {
  name: 'ForgotPasswordForm',
  components: {
    LandingInput,
    Loader
  },
  mixins: [
    formHasErrors
  ],
  data () {
    return {
      isLoading: false,
      error: null,
      sent: false,
      values: {
        email: null,
        'g-recaptcha-response': null
      }
    }
  },
  created () {
    this.initCaptcha();
  },
  beforeDestroy() {
    if(this.$recaptchaInstance){
      this.$recaptchaInstance.hideBadge();
    }
  },
  methods: {
    checkForm () {
      let hasErrors = false
      this.clearErrors()
      if (!this.values.email) {
        this.addError('email', this.$t('verify.email_required'))
        hasErrors = true
      } else if (!isEmail(this.values.email)) {
        this.addError('email', this.$t('verify.email_invalid'))
        hasErrors = true
      }
      return !hasErrors
    },
    async initCaptcha() {
      await this.$recaptchaLoaded()

      this.$recaptchaInstance.showBadge();
    },
    async submit () {
      this.isLoading = true
      this.checkForm()
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "register".
      this.token = await this.$recaptcha('passwordreset');

      if(!this.token) {
        this.isLoading = false
        await this.$store.dispatch('showErrorNotification', 'Problem with invisible captcha validation, please try to reload the page!')
        return false;
      }

      axios.post(this.$api.passwordReset + '/send', { email: this.values.email, 'g-recaptcha-response': this.token }).then(response => {
        this.sent = true
        this.isLoading = false

        const recaptcha = this.$recaptchaInstance
        recaptcha.hideBadge()

      }).catch(error => {
        this.isLoading = false
        if (error.response) {
          if (error.response.data.data && error.response.data.data.message) {
            this.error = error.response.data.data.message
          }
          if (error.response.data && error.response.data.errors) {
            this.errors = Object.assign({}, error.response.data.errors)
          }
        }
      })
    }
  }
}
</script>
