<template>
  <div>
    <template v-if="mfa_method === 'authenticator'">
      <MfaAuthForm />
    </template>
    <template v-if="mfa_method === 'phone'">
      <MfaPhoneForm :mid="message_id" />
    </template>
    <template v-if="mfa_method === 'email'">
      <MfaEmailForm />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import MfaAuthForm from '../components/landing/MfaAuthForm'
import MfaPhoneForm from '../components/landing/MfaPhoneForm'
import MfaEmailForm from "@/components/landing/MfaEmailForm.vue";

export default {
  name: 'LoginMfaPage',
  components: {
    MfaEmailForm,
    MfaAuthForm,
    MfaPhoneForm
  },
  data () {
    return {}
  },
  computed: {
    ...mapState({
      mfa_method: state => state.login.mfa_method,
      message_id: state => state.login.message_id
    })
  },
  mounted () {
    if (!this.mfa_method) {
      this.$router.push({ name: 'login' })
    }
  }
}
</script>
