<template>
  <div class="kt-login__forgot">
    <div class="kt-login__head">
      <h3 class="kt-login__title">{{ $t('title.forgotPassword') }}</h3>
      <div class="kt-login__desc">{{ $t('login.reset_password_instruction') }}</div>
    </div>

    <ForgotPasswordForm />

    <LoginBox
      route="login"
      :title="$t('login.login_link')"
      :action="$t('login.login_link_action')"
    />
  </div>
</template>

<script>
import ForgotPasswordForm from '../components/forms/ForgotPasswordForm'
import LoginBox from '../components/landing/LandingLoginBox'
export default {
  name: 'ForgotPasswordPage',
  components: {
    ForgotPasswordForm,
    LoginBox
  }
}
</script>
