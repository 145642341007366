<template>
  <Loader :loading="isLoading">
    <form @submit.prevent="submit" class="kt-form">
      <div
        v-if="error"
        class="col"
      >
        <div
          class="alert alert-danger"
          role="alert"
        >
          {{ error }}
        </div>
      </div>

      <LandingInput
        v-model="values.first_name"
        name="first_name"
        :placeholder="$t('register.first_name')"
        :errors="errors"
        autocomplete="given-name"
      />
      <LandingInput
        v-model="values.last_name"
        name="last_name"
        :placeholder="$t('register.last_name')"
        :errors="errors"
        autocomplete="family-name"
      />
      <LandingInput
        v-model="values.email"
        type="email"
        name="email"
        :placeholder="$t('login.email_ph')"
        :errors="errors"
      />
      <LandingInput
        ref="phoneInput"
        v-model="values.phone"
        type="phone"
        name="phone"
        :placeholder="$t('register.phone')"
        :errors="errors"
        @input="checkNumber"
      />
      <LandingInput
        v-model="values.password"
        type="password"
        name="password"
        :placeholder="$t('register.password')"
        :errors="errors"
      />
      <LandingInput
        v-model="values.password_repeat"
        type="password"
        name="password_repeat"
        :placeholder="$t('register.password_confirm')"
        :errors="errors"
      />



      <div class="row kt-login__extra">
        <div class="col kt-align-left">
          <label class="kt-checkbox">
            <input
              v-model="values.agreed"
              type="checkbox"
              class="form-control"
              required
            >
              {{ $t('register.agree') }}
              <router-link :to="{name: 'terms'}" target="_blank" class="kt-link kt-login__link kt-font-bold">
                {{ $t('register.terms_link') }}
              </router-link>
              <span></span>
          </label>
          <span
              v-if="errors.hasOwnProperty('agreed')"
              class="invalid-feedback aligned_feed"
              :style="{display: errors.hasOwnProperty('agreed') ? 'block' : 'none'}"
          >
            {{ errors.agreed[0] }}
          </span>
        </div>
      </div>

      <div class="kt-login__actions">
        <button
                type="submit"
                :disabled="isLoading || !values.agreed"
                id="kt_login_signup_submit"
                class="btn btn-brand btn-elevate kt-login__btn-primary"
        >
          {{ $t('register.sign_up') }}
        </button>
        &nbsp;&nbsp;
        <button
                :to="{name: 'login'}"
                id="kt_login_signup_cancel"
                class="btn btn-light btn-elevate kt-login__btn-secondary"
                @click.prevent="$router.push({name: 'login'})"
        >
          {{ $t('general.cancel') }}
        </button>
      </div>
    </form>
  </Loader>
</template>

<script>
import Loader from '../global/Loader'
import LandingInput from './LandingInput'
import formHasErrors from '../../mixins/formHasErrors'
import isEmail from 'validator/lib/isEmail'
import i18n from "../../i18n";
import { parseFullName } from "parse-full-name";

export default {
  name: 'RegistrationForm',
  components: {
    LandingInput,
    Loader
  },
  mixins: [
    formHasErrors
  ],
  data () {
    return {
      isLoading: false,
      error: null,
      values: {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        password: null,
        password_repeat: null,
        agreed: false,
        lang: this.$i18n.locale,
        'g-recaptcha-response': null
      },
      metaData: {
        account_type: 'individual',
        account: null
      }
    }
  },
  created () {
    if (this.$route.query.account) {
      this.metaData.account = this.$route.query.account
    }
    if(this.$route.query.email){
      if(isEmail(this.$route.query.email)){
        this.values.email = this.$route.query.email;
      }
    }
    if(this.$route.query.name){
      if(this.checkName(this.$route.query.name)){
        this.fillSeparateNames(this.$route.query.name);
      }
    }
    this.initCaptcha();
  },
  beforeDestroy() {
    if(this.$recaptchaInstance){
      this.$recaptchaInstance.hideBadge();
    }
  },
  methods: {
    checkForm () {
      let hasErrors = false
      this.clearErrors()
      if (!this.values.first_name) {
        this.addError('first_name', this.$t('verify.first_required'))
        hasErrors = true
      }
      if (!this.values.last_name) {
        this.addError('last_name', this.$t('verify.last_required'))
        hasErrors = true
      }
      if (!this.values.email) {
        this.addError('email', this.$t('verify.email_required'))
        hasErrors = true
      } else if (!isEmail(this.values.email)) {
        this.addError('email', this.$t('verify.email_invalid'))
        hasErrors = true
      }
      if (!this.values.password) {
        this.addError('password', this.$t('verify.password_required'))
        hasErrors = true
      } else if (this.values.password.length < 8) {
        this.addError('password', this.$t('verify.password_length'))
        hasErrors = true
      } else if (this.values.password !== this.values.password_repeat) {
        this.addError('password_repeat', this.$t('verify.password_match'))
        hasErrors = true
      }
      if (!this.values.phone) {
        this.addError('phone', this.$t('verify.phone_required'))
        hasErrors = true
      }
      if (!this.$refs.phoneInput || !this.$refs.phoneInput.isValidPhone()) {
        this.addError('phone', this.$t('verify.phone_invalid'))
        hasErrors = true
      }
      // if (window.AppConfig.blockedCountries.includes(this.$refs.phoneInput.countryCode().toUpperCase())) {
      //   this.addError('phone', 'You can\'t use ' + this.$refs.phoneInput.countryCode().toUpperCase() + ' based phone numbers')
      //   hasErrors = true
      // }
      return !hasErrors
    },
    async initCaptcha() {
      await this.$recaptchaLoaded()

      this.$recaptchaInstance.showBadge();
    },
    async submit () {
      this.isLoading = true
      this.error = null
      await this.$recaptchaLoaded()
      if (!this.checkForm()) {
        this.isLoading = false
      } else {
        // Execute reCAPTCHA with action "register".
        this.token = await this.$recaptcha('register');

        if(!this.token) {
          this.isLoading = false
          await this.$store.dispatch('showErrorNotification', 'Problem with invisible captcha validation, please try to reload the page!')
          return false;
        }

        // temporarily
        //await this.$store.dispatch('showErrorNotification', 'Registration is temporarily disabled!')
        //return false;

        this.values['g-recaptcha-response'] = this.token;

        if (this.$refs.phoneInput && this.$refs.phoneInput.countryCode()) {
          this.metaData.phone_country = this.$refs.phoneInput.countryCode().toUpperCase()
        }

        axios.post(this.$api.register, { ...this.metaData, ...this.values })
          .then((response) => {
            this.isLoading = false
            const recaptcha = this.$recaptchaInstance
            recaptcha.hideBadge()

            if (response.status > 200 && response.status < 300) {
              this.$store.dispatch('setAuthUser').then(response => {
                this.isLoading = false
                // if (response.data.data.logged_in) {
                //   this.$router.push({ name: 'dashboard' })
                // } else {
                //   this.$router.push({ name: 'profile' })
                // }
                this.$router.push({ name: 'dashboard', params: {locale: i18n.locale} })
                this.$store.dispatch('showSuccessNotification', this.$t('login.success'))
              })
            }
          }).catch((error) => {
            this.isLoading = false
            if (error.response) {
              if (error.response.data.data && error.response.data.data.message) {
                this.error = error.response.data.data.message
              }
              if (error.response.data && error.response.data.errors) {
                this.errors = Object.assign({}, error.response.data.errors)
              }
            }
          })
      }
    },
    checkNumber () {
      if (this.$refs.phoneInput.isValidPhone() && Array.isArray(this.errors.phone) && this.errors.phone.length > 0) {
        this.errors.phone = []
      }
    },
    checkName (val) {
      let rexp = /^[a-zA-Z\s.,-]+$/;
      if(rexp.test(val)){
        return true;
      }
      return false;
    },
    fillSeparateNames (val) {
      let name = parseFullName(val);
      if(name.first){
        this.values.first_name = name.first;
      }
      if(name.last){
        this.values.last_name = name.last;
      }
    }
  }
}
</script>

<style>
  .kt-login.kt-login--v3 .kt-login__wrapper .kt-login__container .kt-form .phone .form-control {
      padding-left: 60px;
  }
</style>
