<template>
  <!-- begin:: Footer -->
  <div class="kt-footer-landing kt-grid__item" id="kt_footer">
    <div class="kt-container  kt-container--fluid ">
      <div class="kt-footer__wrapper">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12 col-md-6 text-center">
              <div class="kt-footer__menu">
                <div class="row">
                  <div class="col-sm-3 col-md-6 col-lg-3 text-center ">
                    <a href="mailto:support@yieldnodes.com" target="_blank" class="kt-link">{{ $t('title.contact') }}</a>
                  </div>
                  <div class="col-sm-3 col-md-6 col-lg-3 text-center ">
                    <router-link :to="{name: 'risk'}" target="_blank" class="kt-link">{{ $t('title.risk') }}</router-link>
                  </div>
                  <div class="col-sm-3 col-md-6 col-lg-3 text-center ">
                    <router-link :to="{name: 'terms'}" target="_blank" class="kt-link">{{ $t('title.terms') }}</router-link>
                  </div>
                  <div class="col-sm-3 col-md-6 col-lg-3 text-center ">
                    <router-link :to="{name: 'privacy'}" target="_blank" class="kt-link">{{ $t('title.privacy') }}</router-link>
                  </div>
                </div>
              </div>
            </div>  <!--   col-xs-12 col-md-6-->

            <div class="col-sm-12 col-md-6 text-center landing-footer-right">
              <div class="kt-footer-landing__copyright"> 2019-2022 © YieldNodes</div>
            </div> <!--   col-xs-12 col-md-6 text-center -->

          </div> <!--   row -->
        </div> <!--   container-fluid -->

      </div>
    </div>
  </div>
  <!-- end:: Footer -->
</template>

<script>
  export default {

  }
</script>
