<template>
    <div class="kt-login__signin">
        <div class="kt-login__head">
            <h3 class="kt-login__title">{{ $t('login.title') }}</h3>
        </div>
        <LoginForm/>

        <LoginBox
                route="register"
                :title="$t('login.register_link')"
                :action="$t('login.register_link_action')"
        />
    </div>
</template>

<script>
  import LoginForm from '../components/forms/LoginForm'
  import LoginBox from '../components/landing/LandingLoginBox'

  export default {
    name: 'LoginPage',
    components: {
      LoginForm,
      LoginBox
    }
  }
</script>
