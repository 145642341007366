<template>
  <div class="kt-login__forgot">
    <div class="kt-login__head">
      <h3 class="kt-login__title">{{ $t('mfa.title') }}</h3>
      <div class="kt-login__desc">{{ $t('mfa.app_title') }}</div>
    </div>
    <Loader :loading="isLoading">
      <form
        @submit.prevent="verify"
        class="kt-form"
      >
        <transition name="fade">
          <div
            v-if="error"
            class="alert alert-danger text-center"
          >
            {{ error }}
          </div>
        </transition>

        <div class="input-group">
          <MaskedInput
            id="code"
            ref="codeInput"
            v-model="code"
            type="text"
            class="form-control input-lg text-center mb-sm"
            placeholder="123 456"
            :mask="[/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/]"
            :guide="false"
            placeholder-char="_"
          />
        </div>

        <div class="kt-login__actions">
          <button
            class="btn btn-brand btn-elevate kt-login__btn-primary"
            type="submit"
          >
            {{ $t('mfa.verify') }}
          </button>
        </div>
      </form>
    </Loader>
  </div>
</template>

<script>
import Loader from '../global/Loader'
import { getNumeric } from '../../helpers/strings'

export default {
  name: 'MfaAuthForm',
  components: {
    Loader
  },
  data () {
    return {
      isLoading: false,
      code: null,
      error: null
    }
  },
  watch: {
    code (newVal) {
      if (newVal && getNumeric(newVal).length === 6) {
        this.verify()
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs.codeInput.$refs.input.focus()
    })
  },
  methods: {
    verify () {
      this.error = null
      if (this.code) {
        this.isLoading = true
        axios.post(this.$api.loginMfa, { code: getNumeric(this.code), method: 'authenticator' }).then((response) => {
          if (response.data.data.status === 'logged_in') {
            this.$store.dispatch('setAuthUser').then((response) => {
              let redirect = window.localStorage.getItem('redirect')
              if (redirect) {
                window.localStorage.removeItem('redirect')
                redirect = JSON.parse(redirect)
                this.$router.push(redirect)
              } else {
                this.$router.push({ name: 'dashboard' })
              }
            }).catch((error) => {})
          }
          // this.isLoading = false
        }).catch((error) => {
          this.isLoading = false
          this.error = this.$t('mfa.code_invalid')
        })
      } else {
        this.error = this.$t('mfa.error_enter_code_app')
      }
    }
  }
}
</script>
