<template>
  <div class="kt-login__forgot">
    <div class="kt-login__head">
      <h3 class="kt-login__title">{{ $t('mfa.title') }}</h3>
      <div class="kt-login__desc">{{ $t('mfa.sms_title') }}</div>
    </div>
    <Loader :loading="isLoading">
      <form
        @submit.prevent="verify"
        class="kt-form"
      >
        <transition name="fade">
          <div
            v-if="message"
            class="alert"
            :class="{'alert-danger': messageType === 'error', 'alert-success': messageType === 'success',
                     'alert-warning': messageType === 'warning'}"
          >
            {{ message }}
          </div>
        </transition>

        <div class="input-group">
          <MaskedInput
            id="code"
            ref="codeInput"
            v-model="code"
            type="text"
            class="form-control input-lg text-center mb-sm"
            placeholder="123 456"
            :mask="[/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/]"
            :guide="false"
            placeholder-char="_"
          />
        </div>
        <div class="kt-login__actions">
          <button
            class="btn btn-brand btn-elevate kt-login__btn-primary"
            type="submit"
          >
            {{ $t('mfa.verify') }}
          </button>
        </div>

        <div class="kt-separator kt-separator--space-md kt-separator--border-dashed"></div>

        <h6 class="text-center">
          <a
            href="#"
            @click.prevent="showResend"
          >
            {{ $t('mfa.sms_delay') }}
          </a>
        </h6>

        <div
          v-if="resend"
          class="text-center"
        >
          <button
            class="btn btn-info mr-1"
            @click.prevent="resendSms"
          >
            {{ $t('mfa.sms_resend') }}
          </button>&nbsp;
          <button
            class="btn btn-info"
            @click.prevent="call"
          >
            {{ $t('mfa.sms_call') }}
          </button>
        </div>
      </form>
    </Loader>
  </div>
</template>

<script>
import axios from 'axios';
import Loader from '../global/Loader'
import { getNumeric } from '../../helpers/strings'

export default {
  name: 'MfaPhoneForm',
  components: {
    Loader
  },
  props: {
    mid: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      code: null,
      message: null,
      messageType: 'error',
      messageId: this.mid,
      timer: null,
      resend: false,
      type: 'sms'
    }
  },
  watch: {
    code (newVal) {
      if (newVal && getNumeric(newVal).length === 6) {
        this.verify()
      }
    }
  },
  created () {
    if (this.messageId) {
      this.checkStatus()
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs.codeInput.$refs.input.focus()
    })
  },
  methods: {
    verify () {
      if (this.code) {
        this.isLoading = true
        this.message = null
        if (this.timer) {
          clearTimeout(this.timer)
        }
        axios.post(this.$api.loginMfa, { code: getNumeric(this.code), method: 'phone' }).then((response) => {
          if (response.data.data.status === 'logged_in') {
            this.$store.dispatch('setAuthUser').then((response) => {
              // if (response.data.data.logged_in === 0) {
              //   this.$router.push({ name: 'profile' })
              // } else {
              let redirect = window.localStorage.getItem('redirect')
              if (redirect) {
                window.localStorage.removeItem('redirect')
                redirect = JSON.parse(redirect)
                this.$router.push(redirect)
              } else {
                this.$router.push({ name: 'dashboard' })
              }
              // }
            }).catch((error) => {})
          }
          // this.isLoading = false
        }).catch((error) => {
          this.messageType = 'error'
          if (error.response) {
            if (error.response.status == 429) {
              this.message = this.$t('mfa.sms_too_many')
            } else if (error.response.status == 500) {
              this.message = error.response.data.data.message
            } else if (error.response.status == 401) {
              this.message = this.$t('mfa.code_invalid')
            }
          }
          this.isLoading = false
        })
      } else {
        this.$store.dispatch('showErrorNotification', this.$t('mfa.error_enter_code'))
      }
    },
    showResend () {
      this.resend = !this.resend
    },
    checkStatus () {
      if (this.messageId) {
        axios.get(`${this.$api.phoneMessageStatus}/${this.type}/${this.messageId}`).then((response) => {

          this.isLoading = false
          this.message = response.data.data.message
          if (response.status == 201) {
            this.messageType = 'success'
            if (this.timer) {
              clearTimeout(this.timer)
            }
            this.timer = setTimeout(() => {
              this.message = ''
            }, 5000)
          } else if (response.status == 202) {
            this.messageType = 'warning'
            this.timer = setTimeout(() => this.checkStatus(), 1000)
          }
        }).catch((error) => {
          this.messageType = 'error'
          if (error.response && error.response.status == 500) {
            this.message = error.response.data.data.message
          } else {
            this.message = this.$t('general.unknown_error')
          }
        })
      }
    },
    call () {
      this.message = null
      this.isLoading = true
      if (this.timer) {
        clearTimeout(this.timer)
      }
      axios.patch(this.$api.loginMfaCall).then((response) => {
        if (response.status == 201) {
          this.messageId = response.data.data.message_id
          this.type = response.data.data.type
          this.checkStatus()
          this.resend = false
        }
        this.isLoading = false
      }).catch((error) => {
        this.messageType = 'error'
        if (error.response.status == 500 || error.response.status == 429) {
          this.message = error.response.data.data.message
        }
        this.isLoading = false
      })
    },
    resendSms () {
      this.message = null
      this.isLoading = true
      if (this.timer) {
        clearTimeout(this.timer)
      }
      axios.patch(this.$api.loginMfaResend).then((response) => {
        if (response.status == 201) {
          this.messageId = response.data.data.message_id
          this.type = response.data.data.type
          this.checkStatus()
          this.resend = false
        }
        this.isLoading = false
      }).catch((error) => {
        this.messageType = 'error'
        if (error.response.status == 500 || error.response.status == 429 || error.response.status == 422) {
          this.message = error.response.data.data.message
        }
        this.isLoading = false
      })
    }
  }
}
</script>
