<template>
    <div class="kt-login__account">
        <span class="kt-login__account-msg">
            {{ title }}
        </span>
        <router-link
            :to="{name: route}"
            class="kt-login__account-link"
        >
            {{ action }}
        </router-link>
    </div>
</template>

<script>
  export default {
    name: 'LandingLoginBox',
    props: {
      route: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: true
      },
      action: {
        type: String,
        required: true
      }
    }
  }
</script>
