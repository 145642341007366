<template>
  <div class="row login-pages">
    <div class="col-md-10 offset-md-1 login-wrapper">
      <div class="content-wrap">
        <Loader :loading="isLoading">
          <template v-if="!isLoading">
            <h3
              v-if="unsubscribed"
              class="text-center"
            >
              {{ message }}
            </h3>
            <h3
              v-if="!unsubscribed"
              class="text-center"
            >
              Error. Try again later!
            </h3>
          </template>
        </Loader>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from 'js/components/global/Loader'
export default {
  name: 'EmailUnsubscribePage',
  components: {
    Loader
  },
  data () {
    return {
      isLoading: true,
      unsubscribed: false,
      message: ''
    }
  },
  created () {
    const token = this.$route.params.token
    axios.get(this.$api.unsubscribeEmail + '/' + token).then(response => {
      this.message = response.data.data.message
      this.isLoading = false
      this.unsubscribed = true
    }).catch(error => {
      this.isLoading = false
      this.unsubscribed = false
    })
  }
}
</script>
