<template>
  <div class="kt-grid kt-grid--ver kt-grid--root kt-page">
    <div class="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v3" id="kt_login">
      <div class="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
        <div class="kt-login__container d-flex flex-column align-items-stretch">
          <div class="container-fluid flex-grow-1 d-flex">
            <div class="row flex-grow-1">
              <div class="col-md-6 col-sm-12 login-form-md-left">
                <div class="kt-login__logo text-center">
                  <img src="~images/new/logos/yield-nodes-logo.svg" alt="YieldNodes">
                </div>
                <div class="locale-switcher">
                  <select class="form-control-sm" :value="$i18n.locale" @change.prevent="changeLocale">
                    <option :value="locale.code" v-for="locale in locales" :key="locale.code">
                      {{ locale.name }}
                    </option>
                  </select>
                </div>
                <router-view :key="$route.fullPath" />
              </div>
              <div class="col-md-6 login-form-md-right">
                <div class="kt-login_desktop_logo text-center">
                  <img src="~images/new/logos/yield-nodes-logo.svg" alt="YieldNodes">
                </div>
              </div>
            </div>
          </div>
          <FooterComponentLanding/>
        </div>
      </div>
    </div>
    <Notification/>
  </div>
</template>

<script>
  import Notification from '../components/global/Notification'
  import FooterComponentLanding from '../components/functional/FooterComponentLanding'
  import {siteName} from "../config/api"
  import { getSupportedLocales, supportedLocalesInclude } from "../util/i18n/supported-locales"
  import Vue from 'vue'
  import { VueReCaptcha } from 'vue-recaptcha-v3'

  Vue.use(VueReCaptcha, {
    siteKey: '6Lfx8EIaAAAAAAiVH47DfK8rchjeN7WgSZrDM5sU',
    loaderOptions: {
      //autoHideBadge: true
    }
  });

  export default {
    name: 'Landing',
    components: {
      Notification,
      FooterComponentLanding
    },
    data() {
      return {
        sitename: siteName,
        locales: getSupportedLocales()
      }
    },
    methods: {
      changeLocale(e) {
        if(supportedLocalesInclude(e.target.value)){
          const locale = e.target.value
          this.$i18n.locale = locale;
          this.$router.push({
            name: this.$route.name,
            params: { locale: locale }
          })
        }
      }
    }
  }
</script>

<style lang="scss">
  .kt-landing-bg {
    background-image: url(~images/new/bg/bg-3.jpg);
  }
</style>
