<template>

</template>
<script>
export default {
  name: 'LogoutPage',
  beforeCreate () {
    this.$store.dispatch('logoutRequest').then((response) => {
      if (this.$route.query.demo) {
        this.$router.push({ name: 'register' }, () => {
          this.$store.dispatch('showSuccessNotification', this.$t('login.logout_success'))
        })
      } else {
        this.$router.push({ name: 'login' }, () => {
          this.$store.dispatch('showSuccessNotification', this.$t('login.logout_success'))
        })
      }
    }).catch(err => {
      this.$store.dispatch('showSuccessNotification', this.$t('login.logout_success'))
    })
  }
}
</script>
