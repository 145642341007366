<template>
  <div class="input-group" :class="{'phone': type === 'phone'}">
    <template v-if="type === 'phone'">
      <PhoneInput
        ref="phoneInput"
        v-model="content"
        :name="name"
        :placeholder="placeholder"
        :input-class="{'is-invalid': hasErrors}"
        :required="required"
        :country="phoneCountry"
        @input="handleInput"
      />
    </template>
    <template v-else-if="type === 'checkbox'">
      <input
        v-model="content"
        :type="type"
        :name="name"
        :placeholder="placeholder"
        class="form-control"
        :class="{'is-invalid': hasErrors}"
        :required="required"
        :autocomplete="autocomplete"
        @input="handleInput"
      >
    </template>
    <template v-else>
      <input
        v-model="content"
        :type="type"
        :name="name"
        :placeholder="placeholder"
        class="form-control"
        :class="{'is-invalid': hasErrors}"
        :required="required"
        :autocomplete="autocomplete"
        @input="handleInput"
      >
    </template>
    <div
      class="invalid-feedback"
      :style="{'display': hasErrors ? 'block' : 'none'}"
    >
      <template v-if="hasErrors">
        {{ errors[name][0] }}
      </template>
    </div>
  </div>
</template>

<script>
import PhoneInput from '../global/PhoneInput'

export default {
  name: 'LandingInput',
  components: {
    PhoneInput
  },
  props: {
    name: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'text'
    },
    autocomplete: {
      type: String,
      default: null
    },
    phoneCountry: {
      type: String,
      default: null
    },
    errors: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      content: this.value
    }
  },
  computed: {
    hasErrors: {
      cache: false,
      get () {
        return this.errors.hasOwnProperty(this.name) && Array.isArray(this.errors[this.name]) && this.errors[this.name].length > 0
      }
    }
  },
  watch: {
    value (newValue) {
      this.content = newValue
    }
  },
  methods: {
    handleInput () {
      this.$emit('input', this.content)
    },
    countryCode () {
      return this.$refs.phoneInput.countryCode.toUpperCase()
    },
    isValidPhone () {
      return this.$refs.phoneInput.isValid()
    }
  }
}
</script>
