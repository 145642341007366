<template>
  <div class="kt-login__forgot">
    <div class="kt-login__head">
      <h3 class="kt-login__title">{{ $t('title.passwordRecovery') }}</h3>
      <div class="kt-login__desc"></div>
    </div>

    <ForgotPasswordConfirmForm />

    <LoginBox
      route="login"
      :title="$t('login.login_link')"
      :action="$t('login.login_link_action')"
    />
  </div>
</template>

<script>
import ForgotPasswordConfirmForm from '../components/forms/ForgotPasswordConfirmForm'
import LoginBox from '../components/landing/LandingLoginBox'
export default {
  name: 'ForgotPasswordConfirmPage',
  components: {
    ForgotPasswordConfirmForm,
    LoginBox
  }
}
</script>
