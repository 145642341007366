<template>
  <div class="kt-login__signup">
    <div class="kt-login__head">
      <h3 class="kt-login__title">{{ $t('title.sign_up') }}</h3>
      <div class="kt-login__desc">{{ $t('register.title') }}</div>
    </div>

    <RegistrationForm />

    <LoginBox
      route="login"
      :title="$t('login.login_link')"
      :action="$t('login.login_link_action')"
    />
  </div>
</template>

<script>
import LoginBox from '../components/landing/LandingLoginBox'
import RegistrationForm from '../components/forms/RegistrationForm'

// const zxcvbn = import('zxcvbn/dist/zxcvbn' /* webpackChunkName: "js/password-check" */).then(response => {});

export default {
  name: 'RegistrationPage',
  components: {
    LoginBox,
    RegistrationForm
  }
}
</script>
