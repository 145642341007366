<template>
  <div class="kt-login__forgot">
    <div class="kt-login__head">
      <h3 class="kt-login__title">{{ $t('mfa.title') }}</h3>
      <div class="kt-login__desc">{{ $t('mfa.email_title') }}</div>
    </div>
    <Loader :loading="isLoading">
      <form
        @submit.prevent="verify"
        class="kt-form"
      >
        <transition name="fade">
          <div
            v-if="message"
            class="alert"
            :class="{'alert-danger': messageType === 'error', 'alert-success': messageType === 'success',
                     'alert-warning': messageType === 'warning'}"
          >
            {{ message }}
          </div>
        </transition>

        <div class="input-group">
          <MaskedInput
            id="code"
            ref="codeInput"
            v-model="code"
            type="text"
            class="form-control input-lg text-center mb-sm"
            placeholder="123 456"
            :mask="[/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/]"
            :guide="false"
            placeholder-char="_"
          />
        </div>
        <div class="kt-login__actions">
          <button
            class="btn btn-brand btn-elevate kt-login__btn-primary"
            type="submit"
          >
            {{ $t('mfa.verify') }}
          </button>
        </div>

        <div class="kt-separator kt-separator--space-md kt-separator--border-dashed"></div>

        <h6 class="text-center">
          <a
            href="#"
            @click.prevent="resendEmail"
          >
            {{ $t('mfa.email_delay') }}
          </a>
        </h6>

      </form>
    </Loader>
  </div>
</template>

<script>
import axios from 'axios';
import Loader from '../global/Loader'
import { getNumeric } from '../../helpers/strings'

export default {
  name: 'MfaEmailForm',
  components: {
    Loader
  },
  props: {
  },
  data () {
    return {
      isLoading: false,
      code: null,
      message: null,
      messageType: 'error',
      timer: null,
      resend: false,
      type: 'sms'
    }
  },
  watch: {
    code (newVal) {
      if (newVal && getNumeric(newVal).length === 6) {
        this.verify()
      }
    }
  },
  created () {
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs.codeInput.$refs.input.focus()
    })
  },
  methods: {
    verify () {
      if (this.code) {
        this.isLoading = true
        this.message = null
        if (this.timer) {
          clearTimeout(this.timer)
        }
        axios.post(this.$api.loginMfa, { code: getNumeric(this.code), method: 'email' }).then((response) => {
          if (response.data.data.status === 'logged_in') {
            this.$store.dispatch('setAuthUser').then((response) => {
              let redirect = window.localStorage.getItem('redirect')
              if (redirect) {
                window.localStorage.removeItem('redirect')
                redirect = JSON.parse(redirect)
                this.$router.push(redirect)
              } else {
                this.$router.push({ name: 'dashboard' })
              }
            }).catch((error) => {})
          }
          // this.isLoading = false
        }).catch((error) => {
          this.messageType = 'error'
          if (error.response) {
            if (error.response.status == 429) {
              this.message = this.$t('mfa.email_too_many')
            } else if (error.response.status == 500) {
              this.message = error.response.data.data.message
            } else if (error.response.status == 401) {
              this.message = this.$t('mfa.code_invalid')
            }
          }
          this.isLoading = false
        })
      } else {
        this.$store.dispatch('showErrorNotification', this.$t('mfa.error_enter_code'))
      }
    },
    resendEmail () {
      this.message = null
      this.isLoading = true
      if (this.timer) {
        clearTimeout(this.timer)
      }
      axios.patch(this.$api.loginMfaEmailResend).then((response) => {
        if (response.status === 201) {
          this.type = response.data.data.type
          this.resend = false
        }
        this.isLoading = false
      }).catch((error) => {
        this.messageType = 'error'
        if (error.response.status === 500 || error.response.status === 429 || error.response.status === 422) {
          this.message = error.response.data.data.message
        }
        this.isLoading = false
      })
    }
  }
}
</script>
