<template>
  <Loader :loading="isLoading">
    <template v-if="!showMfa">
      <form @submit.prevent="submit" class="kt-form">

        <div
          v-if="error"
          class="alert alert-danger text-center"
        >
          {{ error }}
        </div>

        <LandingInput
          v-model="values.email"
          type="email"
          name="email"
          :placeholder="$t('login.email_ph')"
          :errors="errors"
        />
        <LandingInput
          v-model="values.password"
          type="password"
          name="password"
          :placeholder="$t('login.password_ph')"
          :errors="errors"
        />
        <LandingInput
          v-model="values.password_repeat"
          type="password"
          name="password_repeat"
          :placeholder="$t('login.password2_ph')"
          :errors="errors"
        />

        <div class="kt-login__actions">
          <button
                  type="submit"
                  :disabled="isLoading"
                  id="kt_login_forgot_submit"
                  class="btn btn-brand btn-elevate kt-login__btn-primary"
          >
            {{ $t('login.reset_password_action2') }}
          </button>
          &nbsp;&nbsp;
          <button
                  id="kt_login_forgot_cancel"
                  class="btn btn-light btn-elevate kt-login__btn-secondary"
                  @click.prevent="$router.push({name: 'login'})"
          >
            {{ $t('general.cancel') }}
          </button>
        </div>
      </form>
    </template>
    <template v-else>
      <template v-if="mfa_method === 'authenticator'">
        <RecoveryMfaAuthForm
          :external-error="mfa_error"
          @verified="verify"
        />
      </template>
      <template v-if="mfa_method === 'phone'">
        <RecoveryMfaPhoneForm
          :mid="mfa_message_id"
          :external-error="mfa_error"
          @verified="verify"
        />
      </template>
    </template>
  </Loader>
</template>

<script>
import Loader from '../global/Loader'
import LandingInput from './LandingInput'
import isEmail from 'validator/lib/isEmail'
import formHasErrors from '../../mixins/formHasErrors'
import RecoveryMfaAuthForm from '../landing/RecoveryMfaAuthForm'
import RecoveryMfaPhoneForm from '../landing/RecoveryMfaPhoneForm'

export default {
  name: 'ForgotPasswordConfirm',
  components: {
    LandingInput,
    Loader,
    RecoveryMfaAuthForm,
    RecoveryMfaPhoneForm
  },
  mixins: [
    formHasErrors
  ],
  data () {
    return {
      isLoading: false,
      error: null,
      token: this.$route.params.token,
      values: {
        email: this.$route.params.email,
        password: null,
        password_repeat: null
      },
      showMfa: false,
      mfa_method: null,
      mfa_token: null,
      mfa_error: null,
      mfa_message_id: null
    }
  },
  methods: {
    checkForm () {
      let hasErrors = false
      this.clearErrors()
      if (!this.values.email) {
        this.addError('email', this.$t('verify.email_required'))
        hasErrors = true
      } else if (!isEmail(this.values.email)) {
        this.addError('email', this.$t('verify.email_invalid'))
        hasErrors = true
      }
      if (!this.values.password) {
        this.addError('password', this.$t('verify.password_required'))
        hasErrors = true
      } else if (this.values.password.length < 8) {
        this.addError('password', this.$t('verify.password_length'))
        hasErrors = true
      } else if (this.values.password !== this.values.password_repeat) {
        this.addError('password_repeat', this.$t('verify.password_match'))
        hasErrors = true
      }
      return !hasErrors
    },
    verify (data) {
      if (data && data.code) {
        this.mfa_token = data.code
        this.submit()
      }
    },
    submit () {
      this.isLoading = true
      this.error = null
      if (!this.checkForm()) {
        this.isLoading = false
      } else {
        axios.post(this.$api.passwordReset + '/reset', {
          token: this.token,
          email: this.values.email,
          password: this.values.password,
          password_confirmation: this.values.password_repeat,
          mfa_method: this.mfa_method,
          mfa_token: this.mfa_token
        })
          .then((response) => {
            if (response.data.data.status && response.data.data.status === 'requires_mfa') {
              this.isLoading = false
              this.mfa_method = response.data.data.method
              if (response.data.data.method === 'phone') {
                this.mfa_message_id = response.data.data.message_id
              }
              this.showMfa = true
            } else {
              this.$store.dispatch('setAuthUser').then(() => {
                this.$router.replace({ name: 'dashboard' }, () => {
                  this.$store.dispatch('showSuccessNotification', this.$t('login.password_reset_success')).then(() => {})
                })
              })
            }
          }).catch((error) => {
            if (error.response.data && error.response.data.errors) {
              if (error.response.status == 422) {
                this.$store.dispatch('showErrorNotification', this.$t('general.errors_below'))
              }
              if (error.response.data.errors.mfa_token) {
                this.mfa_error = error.response.data.errors.mfa_token[0]
              } else if (error.response.data.data.message) {
                this.error = error.response.data.data.message
              } else {
                this.errors = Object.assign({}, error.response.data.errors)
              }
            }
            this.isLoading = false
          })
      }
    }
  }
}
</script>
