<template>
  <div class="row login-pages">
    <div class="col-md-10 offset-md-1 login-wrapper">
      <div class="content-wrap">
        <Loader :loading="isLoading">
          <template v-if="!isLoading">
            <h3
              v-if="verified"
            >
              {{ message }}
            </h3>
            <h3
              v-if="!verified"
            >
              {{ $t('verify.email_expired') }}
            </h3>
          </template>
          <router-link :to="{name: 'dashboard'}">
            {{ $t('verify.to_my_account') }}
          </router-link>
        </Loader>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from 'js/components/global/Loader'
export default {
  name: 'EmailVerifyPage',
  components: {
    Loader
  },
  data () {
    return {
      isLoading: true,
      verified: false,
      message: ''
    }
  },
  created () {
    const token = this.$route.params.token
    axios.get(this.$api.verifyEmail + '/' + token).then(response => {
      this.message = response.data.data.message
      this.isLoading = false
      this.verified = true
      this.$store.dispatch('setAuthUser').then(() => {}).catch(error => {})
    }).catch(error => {
      this.isLoading = false
      this.verified = false
    })
  }
}
</script>
