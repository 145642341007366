<template>
  <Loader :loading="isLoading">
    <form @submit.prevent="submit" class="kt-form">
      <div v-if="showProcessingAlert" class="alert alert-danger kt-margin-b-0">
        <div class="alert-text">
          <strong>Global Calculation is in Progress.</strong> Login is temporary disabled for several hours to let the process finish succesfully. Once it's over - you would be able to login again.
        </div>
      </div>
      <div
        v-if="error"
        class="col"
      >
        <div
          class="alert alert-danger"
          role="alert"
        >
          {{ error }}
        </div>
      </div>

      <LandingInput
        v-model="values.email"
        type="email"
        name="email"
        :placeholder="$t('login.email_ph')"
        :errors="localErrors"
      />
      <LandingInput
        v-model="values.password"
        type="password"
        name="password"
        :placeholder="$t('login.password_ph')"
        :errors="localErrors"
      />
      <!--<div class="row kt-login__extra">
        <div class="col kt-align-left">
          <label class="kt-checkbox">
            <input
              v-model="values.not_a_bot"
              type="checkbox"
              class="form-control"
              required
            >
            {{ $t('register.not_a_bot') }}
            <span></span>
          </label>
          <span
            v-if="errors.hasOwnProperty('agreed')"
            class="invalid-feedback aligned_feed"
            :style="{display: errors.hasOwnProperty('agreed') ? 'block' : 'none'}"
          >
            {{ errors.agreed[0] }}
          </span>
        </div>
      </div>-->
      <div class="row kt-login__extra">
        <div class="col kt-align-right">
          <router-link :to="{name: 'forgotPassword'}" class="kt-login__link">
            {{ $t('login.recovery_link') }}
          </router-link>
        </div>
      </div>
      <div class="kt-login__actions">
        <button
          type="submit"
          class="btn btn-brand btn-elevate kt-login__btn-primary"
          :disabled="isLoading || showProcessingAlert"
        >
          {{ $t('login.title') }}
        </button>
      </div>

    </form>
  </Loader>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '../global/Loader'
import LandingInput from './LandingInput'
import isEmail from 'validator/lib/isEmail'
import {checkProcessing} from "@/config/api";

export default {
  name: 'LoginForm',
  components: {
    LandingInput,
    Loader
  },
  data () {
    return {
      isLoading: false,
      error: null,
      localErrors: {
        email: [],
        password: []
      },
      values: {
        email: null,
        password: null,
        'g-recaptcha-response': null
      },
      token: null,
      showProcessingAlert: false
    }
  },
  computed: {
    ...mapState({
      errors: state => state.login.errors
    })
  },
  created () {
    this.$store.dispatch('clearLoginErrors')
    //this.checkProcessing();

    this.initCaptcha();
  },
  beforeDestroy() {
    if(this.$recaptchaInstance){
      this.$recaptchaInstance.hideBadge();
    }
  },
  methods: {
    checkForm () {
      this.localErrors = Object.assign({}, { email: [], password: [] })
      let hasErrors = false
      if (!this.values.email) {
        this.addError('email', 'First Name is required')
        hasErrors = true
      } else if (!isEmail(this.values.email)) {
        this.addError('email', 'Email must be a valid email address')
        hasErrors = true
      }
      if (!this.values.password) {
        this.addError('password', 'Password is required')
        hasErrors = true
      }
      return !hasErrors
    },
    checkProcessing() {
      axios.get(this.$api.checkProcessing).then(response => {
        this.showProcessingAlert = false;
      }).catch(error => {
        if (error.response) {
          if (error.response.status === 529){
            this.showProcessingAlert = true;
          }
        }
      })

    },
    async initCaptcha() {
      await this.$recaptchaLoaded()

      this.$recaptchaInstance.showBadge();
    },
    async submit () {
      this.isLoading = true
      await this.$recaptchaLoaded()
      if (!this.checkForm()) {
        this.isLoading = false
        return false
      }
      // Execute reCAPTCHA with action "login".
      this.token = await this.$recaptcha('login')

      if(!this.token) {
        this.isLoading = false
        await this.$store.dispatch('showErrorNotification', 'Problem with invisible captcha validation, please try to reload the page!')
        return false;
      }
      this.values['g-recaptcha-response'] = this.token;

      this.$store.dispatch('loginRequest', this.values)
        .then((response) => {
          this.isLoading = false
          const recaptcha = this.$recaptchaInstance

          recaptcha.hideBadge()

          if (response.response === 'requires_mfa') {
            this.$router.push({ name: 'verifyMfa', params: {locale: response.locale} })
          } else {
            if(response.locale) {
              this.$i18n.locale = response.locale;
            }

            let redirect = window.localStorage.getItem('redirect')
            if (redirect) {
              window.localStorage.removeItem('redirect')
              redirect = JSON.parse(redirect)
              this.$router.push(redirect)
            } else {
              this.$router.push({ name: 'dashboard', params: {locale: response.locale} })
            }
          }
        }).catch(error => {
          this.isLoading = false
        })
    },
    addError (field, message) {
      this.localErrors[field].push(message)
    }
  }
}
</script>
