<template>
  <Loader :loading="isLoading">
    <form
      @submit.prevent="verify"
      class="kt-form"
    >
      <div
        v-if="error"
        class="alert alert-danger"
      >
        {{ error }}
      </div>

      <label for="code">
        {{ $t('mfa.app_title') }}
      </label>
      <div class="input-group">
        <MaskedInput
          id="code"
          ref="codeInput"
          v-model="code"
          type="text"
          class="form-control input-lg text-center mb-sm"
          placeholder="123 456"
          :mask="[/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/]"
          :guide="false"
          placeholder-char="_"
        />
      </div>
      <div class="kt-login__actions">
        <button
          class="btn btn-brand btn-elevate kt-login__btn-primary"
          type="submit"
        >
          {{ $t('mfa.verify') }}
        </button>
      </div>
    </form>
  </Loader>
</template>

<script>
import Loader from 'js/components/global/Loader'
import { getNumeric } from '../../helpers/strings'

export default {
  name: 'MfaAuthForm',
  components: {
    Loader
  },
  props: {
    externalError: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      code: null,
      error: null
    }
  },
  watch: {
    code (newVal) {
      if (newVal && getNumeric(newVal).length === 6) {
        this.verify()
      }
    },
    externalError (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.error = newVal
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs.codeInput.$refs.input.focus()
    })
  },
  methods: {
    verify () {
      this.error = null
      if (this.code) {
        this.$emit('verified', { code: getNumeric(this.code) })
      } else {
        this.error = this.$t('mfa.enter_code_app')
      }
    },
    stopLoading () {
      this.isLoading = false
    }
  }
}
</script>
